import dynamic from 'next/dynamic';

import { ModalSliceProps } from '@ui/slice-machine/slices/modal-slice/ModalSlice';

const ModalSlice = dynamic<ModalSliceProps>(() =>
  import('@ui/slice-machine/slices/modal-slice/ModalSlice').then(
    (val) => val.ModalSlice,
  ),
);

export default ModalSlice;
